import stylesItemList from '../../components/StylesItemList.vue';

export default class {
  constructor(){
    this.stylesArrAll = [];
    this.stylesArr = [];
    document.addEventListener("DOMContentLoaded", (e)=> {
    if($(".styles-list-place").length) this.getStylesItem();
      if(ROOT.pageID=="home" && location.hash!="#debug")this.init();
    })
  }
  init(){
    if($(".styles-list-place").length) this.getStylesItem();
  }

  getStylesItem(){
    var self = this;
    $(".styles-list-place .styles-list-place-item").each(function(i,el) {
      self.stylesArrAll.push($(el).html())
    })
    self.setStylesItem();
  }
  setStylesItem(){
    // 詳細ページ
    var self = this;
    $(".styles-list-place").remove();
    var itemArr = []
    ROOT.stylesListItem = new Vue({
      el: '#js-styles-item-list',
      data: {
        itemdata:self.stylesArr,
        isLoadData:false
      },
      components: {
        'stylesItemList' : stylesItemList,
      },
      template: '<stylesItemList></stylesItemList>'
    })

    for (var i in self.stylesArrAll) {
      var item = self.stylesArrAll[i]
      var num = ~~i + 1;
      if ( (num % 5) == 0 ) {
        itemArr.push(item)
        self.stylesArr.push(itemArr)
        itemArr = []
      }
      else if(self.stylesArrAll.length == num){
        itemArr.push(item)
        self.stylesArr.push(itemArr)
        itemArr = []
      }
      else{
        itemArr.push(item)
      }
    }
  }
}
