export default class {
  constructor(){

    // パラメーター取得
    ROOT.getUrlParams = function() {
      ROOT.urlParams = _.chain(location.search.slice(1).split('&'))
      .map(function(item) { return item.split('='); })
      .object()
      .value();
      return ROOT.urlParams;
    }
    ROOT.getUrlParams()

    var self=this;
    $(document).ready(()=>{
      self.init()
    })
  }
  init(){
    var self=this;



    var copyAttentionHide= _.debounce(function() {
      $('.copy-done').removeClass("popDownShow").addClass("popDownHide");
    },2000);

    window.execCopy=function(string,el){
      var temp = document.createElement('div');
      temp.appendChild(document.createElement('pre')).textContent = string;
      var s = temp.style;
      s.position = 'fixed';
      s.left = '-100%';
      document.body.appendChild(temp);
      document.getSelection().selectAllChildren(temp);
      var result = document.execCommand('copy');
      document.body.removeChild(temp);
      // true なら実行できている falseなら失敗か対応していないか
      if(result){
        if(el){
          $(el).next('.copy-done').addClass("popDownShow").removeClass("popDownHide");
        } else {
          $('.copy-done').addClass("popDownShow").removeClass("popDownHide");
        }
        copyAttentionHide()
      }
      return result;
    }




    window.setCookie=function (cookieName, value, params={}){
      $.cookie(cookieName, value, params);
    }
    window.getCookie=function(cookieName){
      var c = $.cookie();
      var str = '';
      for(var key in c){
        if(key === cookieName){
          str = c[key];
        }
      }
      return str;
    }
    setCookie("cookiecheck",1,{ path: "/" })
    if(getCookie("cookiecheck")!=1){
      $(".cookie-attention").removeClass("hide")
    }


  }
}
