export default class {
  constructor(){
    document.addEventListener("DOMContentLoaded", (e)=> {
      if( ROOT.pageID=="member_mypage" && location.hash!="#debug")this.init();
    })
  }

  init(){

    this.vue = new Vue({
      delimiters: ['[[',']]'],
      el: '.vueApp',
      data: {
        rank:"BLUE",
      },
      created(){
        this.rank = $(".member-rank").text().trim();
      },
      mounted(){
      },
      computed:{
        rankClass:function(){
          if(this.rank=="BLUE"){return "rank-blue"}
          else if(this.rank=="GOLD"){return "rank-gold"}
          else if(this.rank=="PLATINUM"){return "rank-platinum"}
          else if(this.rank=="BLACK"){return "rank-black"}
        }
      },
      watch:{
      },
      methods:{

      }
    })
  }
}
