export default class {
  constructor(){
    var self = this;
    document.addEventListener("DOMContentLoaded", (e)=> {
      self.init();
    })
  }
  init(){
    var self = this;
    self.modalDuration = 0.6;
    /** ↓↓ こだわり検索モーダル ↓↓ **/
    $(".btn-modal-search").on("click",function(){
      $("body").addClass("disabled-click")
      self.searchModalOpen();
      setTimeout(function(){
        $("body").removeClass("disabled-click")
      },self.modalDuration*1000)
      return false;
    });
    $(".btn-close, .overlay").on("click",function(){
      $("body").addClass("disabled-click")
      self.searchModalClose();
      setTimeout(function(){
        $("body").removeClass("disabled-click")
      },self.modalDuration*1000)
      return false;
    });
    /** ↑↑ こだわり検索モーダル ↑↑ **/

    //sp drawer copy
    var spMenu = $(".footer .sp-site-menu").clone();
    $(".sp-drawer-menu").append(spMenu)

    /* form関連
    ***************************/
    $("input[type=text]").not(".uk-input").addClass("uk-input uk-form-large");
    $("input[type=number]").not(".uk-input").addClass("uk-input uk-form-large");
    $("input[type=email]").not(".uk-input").addClass("uk-input uk-form-large");
    $("input[type=password]").not(".uk-input").addClass("uk-input uk-form-large");
    $("input[type=tel]").not(".uk-input").addClass("uk-input uk-form-large");
    $("textarea").not(".uk-textarea").addClass("uk-textarea");
    $("select").not(".uk-select").addClass("uk-select uk-form-large uk-width-1");

    $("input[type=radio]").addClass("uk-radio");
    $("input[type=checkbox]").addClass("uk-checkbox");

    $("input[type=button]").addClass("uk-button uk-button-secondary uk-form-large uk-form-width-medium");


    // 電話番号
    var tels=[]
    $(".holder-tel-input").each(function(){
      $("input",this).each(function(i,el){
        if(i==0){
          $(this).attr({"placeholder":"03","maxlength":3})
        }else{
          $(this).attr({"placeholder":"0000","maxlength":4})
        }
      })
    });

    // aタグのコンディション削除
    $("a").not(".is-link-condition").each(function(){
      var $self=$(this)
      var href=$self.attr("href")
      if(href){
        href=href.split("?condition=");
        if(href.length==2)$self.attr("href",href[0]);
      }
    });


    // 空のステータス削除
    $(".items-status-list .status-item,status-holder .status-item").each(function(){
      if($(this).text()==""){$(this).remove()}
    })

    // セール品の％追加
    $(".price-sale").each(function(){
      var $self = $(this);
      var salePrice=$self.text().trim();
      if(salePrice){
        salePrice=salePrice.replace(/￥|\¥|\,|（税込）/g,"");
      }
      var originalPrice = $self.parent().find('.teika-for-sale').text().trim();
      if(originalPrice){
        originalPrice=originalPrice.replace(/￥|\¥|\,|（税込）/g,"");
      }
      if(salePrice&&originalPrice){
        var offPrice=Math.round((originalPrice-salePrice)/originalPrice*100);
        $self.append(`<span class="price-off">[ ${offPrice}% OFF ]</span>`);
      }
    })



    /* 一覧系
    ***************************/
    var onChangeTarget=function(){
      if (ROOT.isSp) {
        $(".item-list-row ol.uk-grid-large").removeClass("uk-grid-large uk-flex-nowrap").addClass("uk-grid-small")
      } else{
        $(".item-list-row ol.uk-grid-small").removeClass("uk-grid-small").addClass("uk-grid-large uk-flex-nowrap")
      }
    }
    ROOT.ev.on("ON_CHANGE_TARGET",onChangeTarget);
    onChangeTarget();

    this.setItemListStylesFeature();
    this.setSwapPic()
  }
  setSwapPic(){
    /* swap-picのscript ( pc L, M , SP)
    ***************************/
    if($(".section-feature").length){
      $(".section-feature .list-item .swap-pic").each(function(i,el){
        if( i==0 || i==3 ){
          var $self=$(el);
          var imgPath=$self.attr("src");
          var suffixPc = $self.attr("data-suffix-pc");
          var suffixSp = $self.attr("data-suffix-sp");
          $self.addClass("swap-img").attr({
            "data-pc":imgPath.split(".jpg").join(suffixPc+".jpg").split(".png").join(suffixPc+".png").split(".gif").join(suffixPc+".gif"),
            "data-sp":imgPath.split(".jpg").join(suffixSp+".jpg").split(".png").join(suffixSp+".png").split(".gif").join(suffixSp+".gif"),
          });
        }
      });
      ROOT.setSwapImg()
    } else {
      $(".list-item .swap-pic, .image-holder .swap-pic").each(function(i,el){
        var $self=$(el);
        var imgPath=$self.attr("src");
        var suffixPc = $self.attr("data-suffix-pc");
        var suffixSp = $self.attr("data-suffix-sp");
        $self.addClass("swap-img").attr({
          "data-pc":imgPath.split(".jpg").join(suffixPc+".jpg").split(".png").join(suffixPc+".png").split(".gif").join(suffixPc+".gif"),
          "data-sp":imgPath.split(".jpg").join(suffixSp+".jpg").split(".png").join(suffixSp+".png").split(".gif").join(suffixSp+".gif"),
        });
      });
      ROOT.setSwapImg()
    }
  }

  setFavorite(){

    // お気に入りアイコンの独自実装
    /*
    $('.list-layout-row-slider .favorite-custom').each(function(i,el){
      var $listItem = $(el).parents(".list-item")
      var hrefArr = $listItem.find(".itemCode").attr("href").split("/");
      if(hrefArr){
        var itemCode = _.last(hrefArr);
        itemCode = itemCode.split(".")[0];
        var itemCodeArr = itemCode.split("-")[0];
      }
      console.log(itemCode)
    })

    var token=null;
    if($('.list-layout-row-slider .favorite-custom').length){
      $('.wishlistSubmitButton a').each(function(){
        var onclick=$(this).attr("onclick");
        if(onclick)token=onclick.split(",")[3];
        if(token)return false;
      })
    }
    $('.list-layout-row-slider .favorite-custom').each(function(i,el){
      var $listItem = $(el).parents(".list-item")
      var hrefArr = $listItem.find(".itemCode").attr("href").split("/");
      if(hrefArr){
        var itemCode = _.last(hrefArr);
        itemCode = itemCode.split(".")[0];
        var itemCodeArr = itemCode.split("-")[0];
      }

      $(el).find(".wishlistinserttag").on("click",function(e){
        e.preventDefault();
        window.putItemToWishListAfterAjaxCheck(ROOT.siteRoot+"ajax_wishlist_insert.html",itemCode,"",token,$(this).parent());
        return true;
      });

      $(el).find(".wishlistdeletetag").on("click",function(e){
        e.preventDefault();
        window.deleteItemToWishListAfterAjaxCheck(ROOT.siteRoot+"ajax_wishlist_insert.html","",itemCode,"",token,$(this).parent());
        return true;
      });
    })
    */
  }
  setItemListStylesFeature(){
    $('.section-history .list-item , #itemListDetail .list-item').not(".categorized").each(function(i,el){
      var $el=$(el);
      $el.addClass("categorized")
      var $itemCode = $el.find(".item-code");
      if(!$itemCode.length){
        var hrefArr = $el.find(".img-holder a").attr("href")
        if(hrefArr){
          hrefArr=hrefArr.split("/");
          var itemCode = _.last( hrefArr );
          itemCode = itemCode.split(".")[0];
          itemCode = itemCode.split("-")[0];
        }
      }else{
        var itemCode=$itemCode.text().trim()
      }
      if(itemCode){
        var item = _.find(ROOT.JSON_DATA.items,{ITEM_CD:itemCode});
        if(item){
          //console.log(item)
          var cateArr=[item.CATEGORY_CD1,item.CATEGORY_CD2,item.CATEGORY_CD3,item.CATEGORY_CD4];
          var isStyles = cateArr.some(str => str.indexOf("STYLES")!==-1);
          var isFeatures = cateArr.some(str => str.indexOf("FEATURE")!==-1);
          if(isStyles){
            $el.addClass("styles");

          } else if(isFeatures){

            $el.addClass("features");
            var $tag=$('.tag-default',el);

            if($tag.length){
              var cate = ROOT.JSON_DATA.categorys[item.CATEGORY_CD1];
              if(cate){
                $tag.attr("href",ROOT.siteRoot+"category/"+cate.CATEGORY_CD)
                $tag.find('.tag-text').text(cate.CATEGORY_NAME)
              }
            }

          }
        }
      }
    })
  }
  setCategoryList(){
    $(".wide-items-placeholder .list-item").each(function(i,el) {
      $(".list-item-putholder").eq(i).prepend(el);
    })
  }
  searchModalOpen(){
    this.isOpen = true;
    $(".modal-narrow").removeAttr("style")
    setTimeout(function(){
      $(".modal-narrow").addClass("opened")
    },self.menuDuration*1000)
    $("body").removeClass("serchModalClose").addClass("serchModalOpen");
    ROOT.modal.open();
  }
  searchModalClose(){
    this.isOpen = false;
    var scrTop = $( window ).scrollTop()
    $(".modal-narrow").css({
      top: -1 * scrTop
    })
    $("body").addClass("serchModalClose")
    $(".modal-narrow").removeClass("opened")
    setTimeout(function(){
      $("body").removeClass("serchModalOpen")
      ROOT.modal.close();
    },self.modalDuration*1000)
  }
}
