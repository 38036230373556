export default class {
  constructor(){
    document.addEventListener("DOMContentLoaded", (e)=> {
      if((ROOT.pageID=="cart_seisan" || ROOT.pageID=="cart_seisan$amazon_payments") && location.hash!="#debug")this.init();
    })

  }
  init(){
    var self = this;
    var data={
      messageOptions:[],
      messageModel:0,
      wrappingOptions:[],
      wrappingModel:0,
      KESSAI_ID:null,
      KESSAI_ID_CARD:null,
      hasSavedCard:null,
      MEMBER_UPDATE_RADIO_TAG:null,
      couponRadioButton:"",
      couponInputText:""
    }
    var computed={
      isMessage:function(){
        var val = _.some(this.messageOptions,(item,i)=>{
          return item.model
        });
        if(val)this.messageModel=1;
        return val;
      },
      isWrapping:function(){
        var val = _.some(this.wrappingOptions,(item,i)=>{
          return item.model
        });
        if(val){
          this.wrappingModel=1;
          $('input[name="ORDER_H.FREE_ITEM3"]').prop("checked",true)
        }else{
          $('input[name="ORDER_H.FREE_ITEM3"]').prop("checked",false)
        }
        return val;
      },
      isConvenience:function(){
        // コンビニ払込票(ペイジェント)     セイコーマート          ローソン                  ミニストップ                   デイリーヤマザキ            セブンイレブン            ファミリーマート
        if(~~this.KESSAI_ID==234 || ~~this.KESSAI_ID==246 || ~~this.KESSAI_ID==247 || ~~this.KESSAI_ID==248 || ~~this.KESSAI_ID==251 || ~~this.KESSAI_ID==252 || ~~this.KESSAI_ID==253){
        // if(~~this.KESSAI_ID!=204&&~~this.KESSAI_ID!=3&&~~this.KESSAI_ID!=205){
          $("#SEND_HOPE_DATE option").eq(0).prop("selected","selected");
          $("#SEND_HOPE_TIME option").eq(0).prop("selected","selected");
          return true;
        }else{
          return false;
        }
      }
    }
    var watch={
      MEMBER_UPDATE_RADIO_TAG:function(){
        //console.log(this.MEMBER_UPDATE_RADIO_TAG)
      },
      messageModel:function(){
        if(!~~this.messageModel){
          _.each(this.messageOptions,(item)=>{
            item.model=false;
          });
        }
      },
      wrappingModel:function(){
        if(!~~this.wrappingModel){
          _.each(this.wrappingOptions,(item)=>{
            item.model=false;
          });
        }else{
          this.wrappingOptions[0].model=this.wrappingOptions[0].code;
        }
      },
      KESSAI_ID_CARD:function(){
        //console.log("KESSAI_ID_CARD",this.KESSAI_ID_CARD)
        if(this.KESSAI_ID_CARD!==null){
          this.KESSAI_ID="204";
        }
      },
      KESSAI_ID:function(){
        if(~~this.KESSAI_ID!=204){
          this.KESSAI_ID_CARD=null
        }else{

        }
        //console.log(this.KESSAI_ID_CARD)
      },
      couponInputText:function(){
        if(this.couponInputText==""){
          // this.couponRadioButton=1;
        } else {
          // this.couponRadioButton=3;
        }
      },
      couponRadioButton:function(){
        console.log(this.couponRadioButton)
      }
    }


    $(".section-gift-message .cart-option-item").each(function(){
      var $self=$(this);
      var $input=$self.find("input[type=checkbox]");
      var code=$input.val();
      var label=$self.find(".label").text().trim();
      var model=$input.prop("checked")?code:false
      var item = {
        code:code,
        label:label,
        model:model,
        thumb:ROOT.assetsPath+"/assets/img/cart/"+code+".jpg"
      }
      data.messageOptions.push(item);
      $input.attr({
        "v-model":`messageOptions[${data.messageOptions.length-1}].model`,
        "true-value":code
      })
    })
    $(".section-gift-wrapping .cart-option-item").each(function(){
      var $self=$(this);
      var $input=$self.find("input[type=checkbox]");
      var code=$input.val();
      var label=$self.find(".label").text().trim();
      var model=$input.prop("checked")?code:false
      var item = {
        code:code,
        label:label,
        model:model
      }
      data.wrappingOptions.push(item);
      $input.attr({
        "v-model":`wrappingOptions[${data.wrappingOptions.length-1}].model`,
        "true-value":code
      })
    });



    $("input[name='KESSAI_ID']").attr("v-model","KESSAI_ID");
    data.KESSAI_ID =~~ $("input[name='KESSAI_ID']:checked").val();



    if(data.KESSAI_ID==204){
      if($("input[name='SAVED_CARD_VERI_TORIHIKI_ID']").length==0||$("input[name='SAVED_CARD_VERI_TORIHIKI_ID']:checked").val() == ""){
        data.KESSAI_ID_CARD="inputNewCard";
      } else {
        data.KESSAI_ID_CARD="inputSavedCard";
      }
    }

    if($(".savedCardItem").length){
      data.hasSavedCard=true;
    }
    $(".savedCardItem").eq(0).find('input[type=radio]').attr('v-bind:checked','KESSAI_ID_CARD==="inputSavedCard"');

    if($(".kessai-item input[value='204']").length==0){
      $('#inputNewCard').parents(".uk-form-controls").hide()
    }
    //クレジットカードを消す
    $('.kessai-item').each(function(){
      if($(this).find("input[value=204]").length){
        $(this).hide()
      }
    });

    /* クーポン
    ****************/
    data.couponInputText=$(".couponInputText-holder input").val();
    $('.couponInputText-holder input').attr("v-model","couponInputText")
    data.couponRadioButton=$('input[name="COUPON_CHECK"]:checked').val();
    $('input[name="COUPON_CHECK"]').attr("v-model","couponRadioButton")
    $("#coupon_select_1").attr("v-show","couponRadioButton=='1'");
    $("#coupon_select_2").attr("v-show","couponRadioButton=='2'");



    this.vue = ROOT.seisan = new Vue({
      delimiters: ['[[',']]'],
      el: ".vueApp",
      data: Object.assign(data,{
      }),
      created(){
      },
      mounted(){

      },
      computed:Object.assign(computed, {

      }),
      watch:Object.assign(watch,{

      }),
      methods:{
        selectGiftWrapping:function(index){
          _.each(this.wrappingOptions,(item,i)=>{
            if(i != index){
              item.model=false;
            }
          });
        },
        selectMessageCard:function(index){
          // 初回のチェックはここが怪しい
          _.each(this.messageOptions,(item,i)=>{
            if(i != index){
              item.model=false;
            }
          });
        }
      }
    })
  }
}
