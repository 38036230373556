class Resize{
  constructor(){
    ROOT.s={};
    ROOT.s.pre=0;

    ROOT.setSwapImg=function(){
      $(".swap-img").each(function(){
        if(!$(this).attr('data-sp') && $(this).attr('data-pc')){
          $(this).attr("data-sp",$(this).attr("data-pc").split(".jpg").join("-sp.jpg").split(".png").join("-sp.png").split(".gif").join("-sp.gif").split(".svg").join("-sp.svg"))
        }
      });
      if(ROOT.isSp){
        $(".swap-img").each(function() {
          var $this=$(this);
          var nowSrc=$this.attr("src");
          var targetSrc =$this.attr("data-sp");
          if(targetSrc.indexOf("assets/img")!==-1||$this.hasClass("assetsPath")){
            targetSrc=ROOT.assetsPath+targetSrc;
          }
          var targetAttr="src";
          if(nowSrc!=targetSrc){$this.attr(targetAttr,targetSrc);}
        });
      }else{
        $(".swap-img").each(function() {
          var $this=$(this);
          var nowSrc=$this.attr("src");
          var targetSrc=$this.attr("data-pc");
          if(targetSrc.indexOf("assets/img")!==-1||$this.hasClass("assetsPath")){
            targetSrc=ROOT.assetsPath+targetSrc;
          }
          var targetAttr="src";
          if(nowSrc!=targetSrc){$this.attr(targetAttr,targetSrc)};
        });
      }
    }

    var isSpPre=false;
    $(window).on("resize",function(){
      ROOT.s.W = $(window).width();
      ROOT.s.H = window.innerHeight ? window.innerHeight : $(window).height();
      ROOT.s.scale = ROOT.s.W / 959;

      if(ROOT.s.pre==ROOT.s.W && (ROOT.isTablet||ROOT.isMobile)){return true;};
      ROOT.s.pre=ROOT.s.W;

      /*
      */
      if(ROOT.s.W<=959){
        ROOT.isSp=true;
      }else if(ROOT.s.W>959){
        ROOT.isSp=false;
      }
      // sp pc 切り替わりタイミング
      if(isSpPre!=ROOT.isSp && ROOT.ev){
        ROOT.ev.emit("ON_CHANGE_TARGET")
      }
      isSpPre=ROOT.isSp;


      var fontsize;
      ROOT.setSwapImg();

      let str = ROOT.isSp ? "sp" : "pc";

      // .browserFitでブラウザに合わせる
      $(".browserFit").each(function(){
        var margin=~~$(this).attr('data-margin-'+str);
        $(this).css({
          width: ROOT.s.W,
          height: ROOT.s.H-margin,
          //position: "relative",
          overflow: "hidden"
        })
      })

      // .browserFitでブラウザに合わせる
      $(".browserFit-min").each(function(){
        $(this).css({
          "min-width": ROOT.s.W,
          "min-height": ROOT.s.H
        })
      })

      // スマホで 横にしたときに小さくなりすぎさせない処理
      if(ROOT.isSp){
        $(".spPortraitFit").each(function(){
          $(this).css({
            width: Math.min(ROOT.s.W),
            height: Math.max(ROOT.s.W,ROOT.s.H)
          });
        })
      }


      // .sizeContentで比率を保って中央に
      $(".sizeContent").each(function(){
        var w = $(this).attr("data-originalWidth-"+str)||$(this).attr("data-originalWidth");
        var h = $(this).attr("data-originalHeight-"+str)||$(this).attr("data-originalHeight");
        w=~~w; h=~~h;
        var scale = Math.min(ROOT.s.W/w, ROOT.s.H/h);
        scale=Math.round(scale*1000)/1000;
        $(this).css({
          position:"absolute",
          left:(ROOT.s.W-w*scale)*0.5,
          top:(ROOT.s.H-h*scale)*0.5,
          width:w*scale,
          height:h*scale
        })
        $(this).attr("data-scale",scale)
      });

      // .sizeCoverで比率を保って拡大
      $(".sizeCover").each(function(){
        var w = $(this).attr("data-originalWidth-"+str)||$(this).attr("data-originalWidth");
        var h = $(this).attr("data-originalHeight-"+str)||$(this).attr("data-originalHeight");
        w=~~w; h=~~h;
        var $p=$(this).parent();
        var pw = $p.innerWidth();
        var ph = $p.outerHeight();
        var scale = Math.max(pw/w,ph/h);
        //scale=Math.round(scale*1000)/1000;
        $(this).css({
          position:"absolute",
          left:(pw-w*scale)*0.5,
          top:(ph-h*scale)*0.5,
          width: w * scale,
          height: h * scale
        });
        $(this).attr("data-scale",scale)
      });


      $(".bg-wrap .layer").each(function(){
        var w = $(this).attr("data-originalWidth-"+str)||$(this).attr("data-originalWidth");
        var h = $(this).attr("data-originalHeight-"+str)||$(this).attr("data-originalHeight");
        w=~~w; h=~~h;
        var $p=$(this).parent();
        var pw = $p.width();
        var ph = $p.height();
        var scale = Math.max(pw/w,ph/h);
        if(! ROOT.isSp &&scale<1){scale=1}
        else if(ROOT.isSp){scale = ROOT.s.W / 959;}
        scale=Math.round(scale*1000)/1000;
        $(this).css({
          position:"absolute",
          "margin-left":(pw-w*scale)*0.5,
          bottom:0,
          width: w * scale,
          height: h * scale
        });
        $(this).attr("data-scale",scale)
      });

      $(ROOT.s).trigger("resize");

    }).resize()
  }
}
export default Resize;


