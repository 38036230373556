export default class {
  constructor(){
    var self=this;
    $("body").imagesLoaded(function(){
      self.init()
    })
  }
  init(){
    var self=this;
    if ($(".mainVisual-slider").length) {
      let tw = new TimelineLite();
      let twFirst = new TimelineLite();
      $(".mainVisual-slider").each(function(i,el){
        var $el=$(el);
        var length = $el.find(".main-item").length;
        var pagination = $el.find(".swiper-pagination");
        var slideDelay = 7;
        if (length > 1) {

          var option = {
            //init: false,
            autoplay: {
              delay: slideDelay*1000,
              disableOnInteraction:false,
            },
            // loop: true,
            // loopedSlides: length,
            pagination: {
              el: pagination,
              type: 'bullets',
              clickable: true,
              renderBullet: function (index, className) {
                return '<div class="'+className+' n'+index+'"><div class="bullet-line bullet-line-def"></div><div class="bullet-line bullet-line-cover"></div></div>'
              }
            },
            speed: 800,
            simulateTouch: false,
            watchOverflow:true,
            on: {
              init: function () {
                var el = this.el;
                setTimeout(function(){
                  twFirst.to(".mainVisual-slider .swiper-pagination-bullet-active .bullet-line-cover",slideDelay,{width:'100%'});
                },50)
              },
              transitionStart:function() {
                var el = this.el;
                tw.clear();
                twFirst.clear();
                var bulletLine = $(".mainVisual-slider .swiper-pagination-bullet .bullet-line-cover");
                TweenMax.to(bulletLine, 0.4, {width:0,right:0,left:'auto',ease:'easeOutQuart'});
              },
              slideChangeTransitionEnd: function () {
                var el = this.el;
                $(".mainVisual-slider .swiper-pagination-bullet .bullet-line-cover").removeAttr('style')
                tw.to(".mainVisual-slider .swiper-pagination-bullet-active .bullet-line-cover",slideDelay,{width:'100%'});
              }
            },
          }
          var swiper = new Swiper(el,option);
        }

        //swiper.init();

      });
    }
    $(".theme-slide-holder").each(function(i,el){
      var option = {
        effect:'fade',
        watchOverflow:true,
        speed: 800,
        noSwipingClass: 'no-swipe'
      }
      var swiper = new Swiper(el,option);
    });
    self.setWideSlider();
  }
  setWideSlider(){
    $(".wide-slider").not(".isSlideComplete").each(function(i,el){
      $(el).addClass("isSlideComplete");
      var next = $(el).find(".swiper-button-next")
      var prev = $(el).find(".swiper-button-prev")
      var length = $(el).find(".list-item").length;
      var item =  $(el).find(".list-item");
      var firstSliderWidth = $(el).outerWidth(true);
      var firstItemWidth = item.outerWidth(true);
      var option = {
        navigation: {
          nextEl: next,
          prevEl: prev,
        },
        watchOverflow:true,
        speed: 300,
        slidesPerView: "auto",
        watchSlidesVisibility:true,
        simulateTouch: false,
        slidesPerGroup:Math.floor(firstSliderWidth/firstItemWidth),
        on: {
          resize: function () {
            var sliderWidth = $(el).outerWidth(true);
            var itemWidth = item.outerWidth(true);
            this.params.slidesPerGroup = Math.floor(sliderWidth/itemWidth)
          }
        }
      }
      if (!ROOT.isSp) {
        var swiper = new Swiper(el,option);
      }
      ROOT.ev.on("ON_CHANGE_ITEM_IMAGE",function(n){
        if ($(el).hasClass('size-select-slide') && !ROOT.isSp && swiper) {
          setTimeout(function(){
            swiper.update();
          },50);
        }
      });
      ROOT.ev.on("ON_CHANGE_TARGET",function(n){
        if (ROOT.isSp && swiper) {
          swiper.destroy( true, true );
        }
        else if(!ROOT.isSp && !swiper){
          var swiper = new Swiper(el,option);
        }
      });
    });
  }
  setItemDetailSwiper(){
    var self=this;
    var swipers=[];
    $(".item-image-holder").each(function(i,el){
      var $pagination = $(el).find(".swiper-pagination");
      var option = {
        watchOverflow:true,
        speed: 300,
        slidesPerView: "auto",
        watchSlidesVisibility:true,
      }
      if($pagination.length){
        option.pagination={
          el: $pagination[0],
          type: 'bullets',
          clickable: true
        }
      }
      if (ROOT.isSp) {
        if($(el).find(".image-item").length>1){
          swipers[i] = new Swiper(el,option);
        }
      }
      var onSwitch=function(n){
        console.log($(el).hasClass("grid"))
        if (ROOT.isSp && $(el).hasClass("grid")==false) {
          swipers[i] = new Swiper(el,option);
            console.log(swipers[i],i)
        }
        else{
          if(swipers[i]){
            swipers[i].destroy( true, true );
            swipers[i]=null;
            console.log(swipers[i])
          }
        }
      }
      ROOT.ev.on("ON_CHANGE_MODE",onSwitch);
      ROOT.ev.on("ON_CHANGE_TARGET",onSwitch);
    });
    return swipers;

  }
}
