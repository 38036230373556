export default class {
  constructor(){
    $(".main-slideshow").imagesLoaded(()=>{
      this.init()
    })
  }
  init(){
    $(".main-slideshow").each((i,el)=>{
      var option=$(el).attr("data-slideshow-option");
      if (option) {
        option = _.without(option.split(";"),"")
        option = _.map(option,(el)=>{var ary=el.split(":"); return [ary[0].trim(),ary[1].trim()]})
        option=_.object(option);
        var ukslideshow=UIkit.slideshow(el,option);
      }else{
        var ukslideshow=UIkit.slideshow(el);
      }

      var $vmPlayer=$(el).find(".vmPlayer");
      $('.uk-slideshow-nav li a').append("<div class='line'></div>");
      if($vmPlayer.length){
        var player=$vmPlayer.data("vmPlayer")
        $(".uk-slideshow-nav li.uk-active",el).addClass("loading")
        if(ROOT.isSp){
          $(player).on("VIDEO-READY",()=>{
            $(".uk-slideshow-nav li.uk-active",el).removeClass("loading")
          });
        }else{
          $(player).on("VIDEO-PLAY",()=>{
            $(".uk-slideshow-nav li.uk-active",el).removeClass("loading")
          });
        }
        //UIkit.util.on(el, 'itemhidden', function () {})
      }

      $('.uk-slideshow-nav li a .line',el).on('animationend', function() {
        $(this).removeClass("end");
        if($(this).parents(".uk-active").length){
          $(this).addClass("end");
          var nextIndex=ukslideshow.index+1;
          ukslideshow.show(nextIndex)
        }
      });

    })
  }
}
