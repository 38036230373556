var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "list-layout-styles-slider wide-slider swiper-container padding-pc-left-lg padding-sp-left-ll"
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "swiper-wrapper margin0" },
        _vm._l(_vm.$root.itemdata, function(listItem, index) {
          return _c("li", { staticClass: "list-item item swiper-slide" }, [
            _c(
              "div",
              {
                staticClass:
                  "list-alternate uk-flex uk-flex-between uk-flex-wrap uk-flex-row"
              },
              _vm._l(listItem, function(item, index) {
                return _c("div", {
                  staticClass: "list-alternate-item",
                  domProps: { innerHTML: _vm._s(item) }
                })
              }),
              0
            )
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "list-layout-styles-slider-bottom-trigger" })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "slide-nav-holder pcOnly" }, [
      _c("div", { staticClass: "slide-nav-prev-holder" }, [
        _c(
          "div",
          {
            staticClass:
              "swiper-button-prev btn uk-flex uk-flex-center uk-flex-middle uk-border-circle uk-overflow-hidden bg-color-white margin0 box-shadow-def"
          },
          [
            _c("i", {
              staticClass: "icon icon-arrow icon-arrow-left",
              attrs: { "uk-icon": "ka-arrow-left" }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "slide-nav-next-holder" }, [
        _c(
          "div",
          {
            staticClass:
              "swiper-button-next btn uk-flex uk-flex-center uk-flex-middle uk-border-circle uk-overflow-hidden bg-color-white margin0 box-shadow-def"
          },
          [
            _c("i", {
              staticClass: "icon icon-arrow icon-arrow-right",
              attrs: { "uk-icon": "ka-arrow-right" }
            })
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }