export default class {
  constructor(){
    document.addEventListener("DOMContentLoaded", (e)=> {
      if(ROOT.pageID=="member_input" && location.hash!="#debug")this.init();
    })
  }
  init(){
    var self = this;
    var data={
      MEMBER_DEFAULT_KESSAI_ID:null,
      hasSavedCard:null,
      MAIL_FLG:null
    }
    var computed={


    }
    var watch={


    }


    $("input[name='MEMBER_DEFAULT_KESSAI_ID']").attr("v-model","MEMBER_DEFAULT_KESSAI_ID")
    data.MEMBER_DEFAULT_KESSAI_ID=$("input[name='MEMBER_DEFAULT_KESSAI_ID']:checked").val();

    if($(".savedCardItem").length){
      data.hasSavedCard=true;
    }

    $("input[name='MAIL_FLG']").attr("v-model","MAIL_FLG");
    data.MAIL_FLG=$("input[name='MAIL_FLG']:checked").val();


    var $comment=$('.fieldset-mailmagazine .mail-comment');
    var cmments=$comment.text().split("|");
    $comment.text(cmments[0]);
    $('.fieldset-mailmagazine .HTMLFORM_LABEL_GROUP').eq(0).after(`<div class="spacer-sm"></div><p class="mail-comment">${cmments[1]}</p>`);


    this.vue = ROOT.seisan = new Vue({
      delimiters: ['[[',']]'],
      el: ".vueApp",
      data: Object.assign(data,{
      }),
      created(){

      },
      mounted(){

      },
      computed:Object.assign(computed, {

      }),
      watch:Object.assign(watch,{

      }),
      methods:{

      }
    })
  }
}
