export default class {
  constructor() {
    this.controller = new ScrollMagic.Controller();
    this.scrollTriggers=[];
    this.init();
  }

  init(){
    var self=this;
    self.setAnchorLink();
    $("body").imagesLoaded(function(){
      self.execute();
      $(window).on("scroll",function(){
        var windowH = $(window).height();
        var scrollTop = $(window).scrollTop();
        var wrapHeight = $(".wrap").height();
        if (windowH + scrollTop > wrapHeight-1) {
          setTimeout(function(){
            $(".jsStanby").not(".jsShow").addClass("jsShow");
          },500);

        }
      });
    });
  }
  destroy(){
    this.scrollTriggers.forEach(function(el){
      if(el)el.destroy();
    });
    this.scrollTriggers=[];
  }
  execute(){
    var self=this;
    self.destroy();
    var waitList=[];
    var waitList2=[];
    var re = _.debounce( function(){
      if(waitList2.length){
        waitList=waitList.concat(waitList2);
        waitList2=[];
      }
      waitList=_.sortBy(waitList,function(el){
        return $(el).offset().left+$(el).offset().top*5;
      });

      $(waitList).each(function(i,el){
        var $secTrigger=$(el).parents(".secTrigger")
        if($secTrigger.length==1 && !$secTrigger.hasClass("animEnd")){
          waitList2.push(el);
        }else{
          setTimeout(function(){$(el).addClass("jsShow");},i*50);
        }
      });
      waitList=[];
    }, 20);

    $(".jsStanby").not(".jsShow").each(function(i,el){
      var scene = new ScrollMagic.Scene({
        triggerElement: el,
        triggerHook: 0.9,
      }).on("enter", function (e) {
        if(el){
          waitList.push(el);
          re();
        }
        this.destroy()
      })
      //.addIndicators()
      .addTo(self.controller);
      self.scrollTriggers.push(scene);
    })
  }
  setHorizontalScroll(){
    /* 固定ヘッダーでの横スクロール対応
    *********************************/
    if(!ROOT.isSp){
      var preLeft = 0;
      var $window=$(window);
      var $targets=$("header.header");
      $window.on("scroll", function (e) {
        var left = $window.scrollLeft();
        if (preLeft != left) {
          $targets.css("left", -left);
        }
        preLeft = left;
      });
    }
  }
  setAnchorLink(){
    var self=this;
    $(document).on("click","a[href^='#']:not(a[href='#'])",function(e){
      e.preventDefault();
      var id = $(this).attr("href");
      self.gotoScroll(id);
      return false;
    });
  }
  gotoScroll(el){
    var pos=$(el).length?$(el).offset().top:0;
    if(!ROOT.isSp && $("header.header .g-menu").length){
      if($(window).scrollTop() > pos){
        pos -= $("header.header").outerHeight();
      }
    }
    $("html,body").animate({scrollTop:pos},800,"easeInOutQuart");
  }
  styleFixNav(){
    var self=this;
    $("body").imagesLoaded(function(){
      $(".list-layout-styles-slider").each(function(i,el){
        var height = $(el).outerHeight();
        var scene = new ScrollMagic.Scene({
          triggerElement: el,
          triggerHook: 0,
          duration:height,
        }).on("enter", function (e) {
          $(el).addClass("fixPos")
        }).on("leave", function (e) {
          if (e.scrollDirection=='REVERSE') {
            $(el).removeClass("fixPos absBottom")
          }
          else{
            $(el).addClass("absBottom")
          }
        })
        //.addIndicators()
        .addTo(self.controller);
      })
      $(".list-layout-styles-slider-bottom-trigger").each(function(i,el){
        var scene = new ScrollMagic.Scene({
          triggerElement: el,
          triggerHook: 1,
        }).on("enter", function (e) {
          $('.list-layout-styles-slider').addClass("absBottom")
        }).on("leave", function (e) {
          $('.list-layout-styles-slider').removeClass("absBottom")
        })
        //.addIndicators()
        .addTo(self.controller);
      })
    });
  }
}

