
export default class {
  constructor(){
    this.init();
  }
  init(){
    var self = this;
    self.ecUrl = (document.domain=='www.karrimor.jp') ? 'https://www.karrimor.jp' : 'https://demo-karrimor.ebisumart.com';
    const axios = require('axios')
    const api = axios.create()
    self.vue = new Vue({
      delimiters: ['[[',']]'],
      el: ".wp-vue-holder",
      data:{
        baseUrl:'https://prd.karrimor-cms.com/wp-json/wp/v2/',
        catMagazineID:null,
        catAmbassadorID:null,
        catAll:[],
        magazineItems:[],
        ambassadorItems:[],
        isSetSwiper:false
      },
      created(){

      },
      mounted(){

      },
      created:function(){
        var self = this;
        self.getCat();
      },
      mounted:function(){
        var self = this;
        $(function(){
          $("body").imagesLoaded(function(){
            ROOT.ev.emit('WP_CONTENT_SHOWN', 1);
          })
        });
      },
      watch:{
        isSetSwiper(){
          var self = this;
        },
      },
      methods:{
        getCat(){
          var self = this;
          var catAllUrl = self.baseUrl+'categories?per_page=100';
          var MagazineUrl = self.baseUrl+'categories?slug=magazine&?per_page=20'
          var AmbassadorUrl = self.baseUrl+'categories?slug=ambassador&?per_page=20'
          Promise.all([
            api.get(catAllUrl),
            api.get(MagazineUrl),
            api.get(AmbassadorUrl)
          ]).then(([catAll,resMagazine,resAmbassador]) => {
            self.catAll = catAll.data;
            self.catMagazineID = resMagazine.data[0].id;
            self.catAmbassadorID = resAmbassador.data[0].id;
            self.getPost()
          })
          .catch((error)=>{
            console.log(error)
          });
        },
        getPost(){
          var self = this;
          var MagazineUrl = self.baseUrl+'posts?categories='+self.catMagazineID+'&per_page=20'
          var AmbassadorUrl = self.baseUrl+'posts?categories='+self.catAmbassadorID+'&per_page=20'
          Promise.all([
            api.get(MagazineUrl),
            api.get(AmbassadorUrl)
          ]).then(([resMagazine,resAmbassador]) => {
            self.magazineItems = resMagazine.data;
            self.ambassadorItems = resAmbassador.data;
            self.setSwiper()
          })
          .catch((error)=>{
            console.log(error)
          });
        },
        setSwiper(){
          var self = this;
          self.isSetSwiper = true;
          setTimeout(function(){
            ROOT.slider.setWideSlider();
          },100)
        },
        categoryStr(names){
          return names[0];
        },
        dateStr(date){
          if(date)return moment(new Date(date)).format("YYYY.M.D");
        },
        catIcon(item){

        },
        linkReplace(link){
          //console.log(link,self.ecUrl)
          return link.replace('https://prd.karrimor-cms.com',self.ecUrl);
        }
      }
    })
  }
}
