export default class {
  constructor(){
    document.addEventListener("DOMContentLoaded", (e)=> {
      if( ROOT.pageID=="wishlist" && location.hash!="#debug")this.init();
    })
  }

  init(){

    this.vue = new Vue({
      delimiters: ['[[',']]'],
      el: '.vueApp',
      data: {
        itemList:[],
        styleList:[]
      },
      created(){
        this.categorizeItems();
        ROOT.ev.on("LOAD_MORE_APPENDED",()=>{
          this.categorizeItems();
        })
      },
      mounted(){
      },
      computed:{
      },
      watch:{
      },
      methods:{
        categorizeItems(){
          // レコメンド アイテム
          $(".wish-list-holder.hide .list-item").each((i,el)=>{
            var itemCode = $(".item-code",el).text().trim();
            if(itemCode){
              itemCode=itemCode.split("-")[0];
            }
            var item = _.find(ROOT.JSON_DATA.items,{ITEM_CD:itemCode});
            if(item){
              var cateArr=[item.CATEGORY_CD1,item.CATEGORY_CD2,item.CATEGORY_CD3,item.CATEGORY_CD4];
              var isStyles = cateArr.some(str => str.indexOf("STYLES")!==-1);
              if(isStyles){
                this.styleList.push($(el).html())
              } else {
                this.itemList.push($(el).html())
              }
              $(el).remove()
            }else{
              this.itemList.push($(el).html())
              $(el).remove()
            }
          });

        }
      }
    })
  }
}
