export default class {
  constructor(){
    if($(".categoryRefine").length)this.init();
  }
  init(){

    var data={}
    var watch={}
    var type=$(".categoryRefine").attr("data-category-type")
    var len=$(".categoryRefine select").length;
    var condition = ROOT.urlParams.condition;
    if(condition){
      condition=decodeURIComponent(condition).split(",")
      _.each(condition,function(item){
        item=item.split(":");
        if(item.length>0)item=item[1]
        $(".categoryRefine select").each(function(i,el){
          if($(el).find(`[value=${item}]`).length){
            $(el).val(item)
          }
        })
      })
    }


    $(".categoryRefine select").each(function(n,el){
      data["category"+n]=$(this).val();
      $(this).attr("v-model",`category${n}`);

      watch["category"+n]=function(){
        var condition=[];
        for(var i=0;i<len;i++){
          if(this["category"+i]!="")condition.push(`${type}:${this["category"+i]}`)
        }
        condition=condition.join(",");
        var param = $.param({condition:condition});
        if (type=='STYLES') {
          location.search="?"+param;
        }
        else if (type=='FEATURE'){
          var path = location.pathname;
          var url = path.split(type)[0];
          location.href = url+type+"/?"+param;
        }
        /**
        **/
      }
    });

    this.vue = new Vue({
      delimiters: ['[[',']]'],
      el: '.vueApp',
      data: Object.assign(data,{}),
      watch: Object.assign(watch,{}),
      created(){
      },
      mounted(){
      },
      methods:{
      }
    })
  }
}
