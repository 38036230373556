export default class {
  constructor(){
    document.addEventListener("DOMContentLoaded", (e)=> {
      if(( ROOT.pageID == "cart_confirm" || ROOT.pageID == "cart_confirm$amazon_payments" )&& location.hash!="#debug")this.init();
    })
  }
  init(){


    this.vue = ROOT.cart_confirm = new Vue({
      delimiters: ['[[',']]'],
      el: ".vueApp",
      data: {
        beforePoint:0,
        usePoint:0,
        addPoint:0,
      },
      created(){
        this.beforePoint=~~$(".before-point").text();
        this.usePoint=~~$(".use-point").text();
        this.addPoint=~~$(".add-point").text();
      },
      mounted(){
      },
      computed:{
        afterPoint:function(){
          return this.beforePoint+this.addPoint-this.usePoint;
        }
      },
      watch:{
      },
      methods:{

      }
    })
  }
}
