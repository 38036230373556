function initialState(){
  return {
    categorys:null,
    items:null,
    isSp:false,
    isChangeITEM_003:false,
    ITEM_003:"", //タイプ
    ITEM_004:"", //性別
    ITEM_006:"", //機能
    ITEM_007:"", //容量
    ITEM_008:"", //サイズ
    ITEM_004_004:0, //UNISEX
    keyword:null,
    andCategory:["10","233","234"],
    selectedCategory1:null,
    checkedCategory:[],
    clearCategory:[],
    sCategory:{},
    minPrice:"",
    maxPrice:"",
    totalItem:0,
    productImagePath:ROOT.productImagePath,
    

    ITEM_006_ITEM_003:{
    "ITEM_006_001":["ITEM_003_001"],
    "ITEM_006_002":["ITEM_003_001"],
    "ITEM_006_003":["ITEM_003_001"],
    "ITEM_006_004":["ITEM_003_001"],
    "ITEM_006_005":["ITEM_003_001"],
    "ITEM_006_006":["ITEM_003_001"],
    "ITEM_006_007":["ITEM_003_001"],
    "ITEM_006_008":["ITEM_003_001"],
    "ITEM_006_009":["ITEM_003_001"],
    "ITEM_006_010":["ITEM_003_001"],
    "ITEM_006_011":["ITEM_003_001"],
    "ITEM_006_012":["ITEM_003_001"],
    "ITEM_006_013":["ITEM_003_001"],
    "ITEM_006_014":["ITEM_003_002","ITEM_003_003"],
    "ITEM_006_015":["ITEM_003_002","ITEM_003_003"],
    "ITEM_006_016":["ITEM_003_002","ITEM_003_003"],
    "ITEM_006_017":["ITEM_003_002","ITEM_003_003"],
    "ITEM_006_018":["ITEM_003_002","ITEM_003_003"],
    "ITEM_006_019":["ITEM_003_002","ITEM_003_003"],
    "ITEM_006_020":["ITEM_003_002","ITEM_003_003"],
    "ITEM_006_021":["ITEM_003_002","ITEM_003_003"],
    "ITEM_006_022":["ITEM_003_002","ITEM_003_003"],
    "ITEM_006_023":["ITEM_003_002"],
    "ITEM_006_024":["ITEM_003_002"],
    "ITEM_006_025":["ITEM_003_003"],
    "ITEM_006_026":["ITEM_003_003"],
    "ITEM_006_027":["ITEM_003_003"]
    }

  }
}
export default {
  name: "search",
  data () {
    return initialState()
  },
  watch:{
    ITEM_003:function(){
      var self=this.ITEM_003;
      this.reset()
      this.isChangeITEM_003=true;
      setTimeout(()=>{this.isChangeITEM_003=false;},500)
      this.ITEM_003 = self;
    },
    ITEM_004:function(){
      if(this.ITEM_004!="ITEM_004_002"){
        this.ITEM_004_004=0;
      }
    },
    checkedCategory(){
      _.each(this.checkedCategory,(item)=>{
        this.clearCategory=_.filter(this.clearCategory, function(category){
          return item.indexOf(category+"_") == -1;
        })
      });
    },
  },
  computed: {
    resultItem: function(){
      if(this.items){
        var cateArr={};
        _.each(this.checkedCategory,(cat)=>{
          // 改装順の配列作成
          var arr=this.parents(cat);

          // 第1カテゴリーでグルーピング
          cateArr[arr[0]]=cateArr[arr[0]]||[];
          cateArr[arr[0]].push(arr);

          // 第2カテゴリーでグルーピング
          cateArr[arr[1]]=cateArr[arr[1]]||[];
          cateArr[arr[1]].push(arr);

          // 第3カテゴリーでグルーピング
          cateArr[arr[2]]=cateArr[arr[2]]||[];
          cateArr[arr[2]].push(arr);

        });


        // 第１カテゴリー選択テキスト
        _.each(cateArr,(arr,key)=>{
          this.$set(this.sCategory,key,_.map(_.map(arr,_.last),(cat)=>{
            return this.categorys[cat].CATEGORY_NAME;
          }).join(", "))
        });

        _.each(this.sCategory,(arr,key)=>{
          if(!cateArr[key]){
            this.$delete(this.sCategory,key)
          }
        })

        // カテゴリー
        if(this.checkedCategory.length==0){
          this.totalItem=0;
          return [];
        }


        // item内の登録カテゴリーの検索
        var cats=[];
        var result= this.items.filter((item)=>{

          // 金額 and 検索
          if(this.minPrice!=""&&~~this.minPrice > ~~item.TEIKA){
            return false;
          }
          if(this.maxPrice!=""&&~~this.maxPrice < ~~item.TEIKA){
            return false;
          }

          cats=[];
          for(var i=1;i<=30;i++){
            if(item["CATEGORY_CD"+i])cats.push(item["CATEGORY_CD"+i])
          }
          // 第1カテゴリー and 検索
          return _.every(cateArr,(arr)=>{
            return _.some(arr,(a)=>{
              return _.contains(cats,_.last(a));
            });
          });
        })
        this.totalItem=result.length;
        return result
      }
    }
  },
  created (){
    this.items=ROOT.JSON_DATA.items.concat();
    this.categorys = Object.assign({}, ROOT.JSON_DATA.categorys);
    this.isSp=ROOT.isSp?true:false;
    //console.log(this.items);
  },
  mounted (){
    //$(".btn-modal-search").trigger("click");
  },
  methods: {
    clear:function(){
      _.each(this.clearCategory,(category)=>{
        this.checkedCategory=_.filter(this.checkedCategory, function(item){
          return item.indexOf(category+"_") == -1;
        })
      });
    },
    itemClick:function(hierarchy,item){
      if(hierarchy==1){
        if(this.selectedCategory1==item){
          this.selectedCategory1=null
        }else{
          this.selectedCategory1=item;
          if (ROOT.isSp) {
            setTimeout(function(){
              $( document ).scrollTop(0);
            },400)
          }
        }
      }
    },
    parents:function(cat){
      var arr=[cat]
      var PARENT=cat;
      var child=cat;
      while(child){
        PARENT=this.categorys[child].CATEGORY_PARENT;
        if(PARENT){
          arr.unshift(PARENT);
          child=PARENT;
        }else{
          child=null;
        }
        PARENT=null;
      }
      return arr;
    },
    close: function(){
      ROOT.html.searchModalClose();
    },
    submit: function() {
      /*
      sort_order sort_order=1（新着順）
      sort_order=2（金額の低い順）
      sort_order=3（金額の高い順）
      sort_order=4（名前順）
      keyword=文字列
      category_cd=
      */

      var result = {};
      if(this.maxPrice)result.maxPrice=this.maxPrice;
      if(this.minPrice)result.minPrice=this.minPrice;
      if(this.keyword)result.keyword=this.keyword;


      var condition =[]
      if(this.ITEM_003!=""){condition.push('ITEM_003:'+this.ITEM_003)}
      if(this.ITEM_004!=""){condition.push('ITEM_004:'+this.ITEM_004)}
      if(this.ITEM_004_004==1){condition.push('ITEM_004:ITEM_004_004')}
      if(this.ITEM_006!=""){condition.push('ITEM_006:'+this.ITEM_006)}
      if(this.ITEM_007!=""){condition.push('ITEM_007:'+this.ITEM_007)}
      if(this.ITEM_008!=""){condition.push('ITEM_008:'+this.ITEM_008)}


      var _condition=[];
      _.each(this.checkedCategory,(cat)=>{
        // 階層順の配列作成
        var arr=this.parents(cat);
        _condition.push( _.last(arr, 2))
      });

      // 整理などはここでやるつもり
      _.each(_condition,(arr)=>{
        condition.push(arr.join(":"))
      });


      if(condition.length){
        result.condition=condition.join(",")
      }else{
        result.category_cd="ITEM";
      }
      var param = $.param(result);



      var isDemo=false;
      if(location.href.indexOf("karrimor.jp")===-1){
        isDemo=true;
      }
      if(isDemo){
        location.href=`/KARRIMOR/item_list.html?`+param;
      }else{
        location.href=`/item_list.html?`+param;
      }


    },
    isITEM_006Mach: function(item){
      if(item.indexOf("ITEM_006")!==-1){
        if(this.ITEM_006_ITEM_003[item].some((str)=>{return str==this.ITEM_003})){
          return true;
        }
        return false;
      }else{
        return true;
      }
    },

    reset: function() {
      Object.assign(this.$data, this.$options.data());
      this.items=ROOT.JSON_DATA.items.concat();
      this.categorys = Object.assign({}, ROOT.JSON_DATA.categorys);
      this.isSp=ROOT.isSp?true:false;

    },
  }

}
