export default class {
  constructor(){
    document.addEventListener("DOMContentLoaded", (e)=> {
      if(ROOT.pageID=="styles_detail" && location.hash!="#debug")this.init();
    })
  }
  init(){
    var self = this;
    var data={
      /*
      valiants:[],
      valiation:{},
      valiationSelects:[]
      */
      tagCategorys:{
        functions:[],
        tabs:[]
      },
      viewmode:0,
      swipers:[],
      itemImages:[],
      selectedValiation:{},
      imgValiatinIndex:null

    }
    var computed={
    }
    var watch={
    }


    // アイテム情報の登録
    $(`[data-item]`).each(function(){
      data[$(this).attr("data-item")]=$(this).text().trim()
    })

    //style relation タイトル取得
    if ($(".section-relation-styles").length) {
      var catCode = $('.section-relation-styles .cate-name').text();
      var catItem = _.findWhere(ROOT.JSON_DATA.categorys,{CATEGORY_CD:catCode})
      if(catItem)$('.section-relation-styles .cate-name').text(catItem.CATEGORY_NAME);
    }



    // シェアボタン
    $(".default-buttons .btn-share").attr("onClick",`window.execCopy('${location.href}',this)`);



    // 初期イメージ登録
    $(".default-images .image-item img").each(function(){
      data.itemImages.push({label:"",src:this.src});
    })

    // バリエーション画像の登録
    $(".item-color-list-holder .list-item").each(function(n,el){
      var label = $(el).find(".item-title").text().trim();
      $(el).find(".img-holder").each(function(i){
        var src=$(this).find("img").attr("src")||$(this).find("img").attr("data-src")
        $(this).remove();
        data.itemImages.push({label:label,src:src});
      });
    });
    //console.log(data.itemImages)



    this.vue = new Vue({
      delimiters: ['[[',']]'],
      el: ".vueApp",
      data: Object.assign(data,{
        modal:null,
        imageViewer:null,
      }),
      created(){
        //$(".default-buttons").hide()
      },
      mounted(){
        //this.openPhotoSwipe();
        this.swipers = ROOT.slider.setItemDetailSwiper();
        this.debounce=_.debounce((n)=>{
          this.swipers[0].slideTo(n);
        }, 100);
      },
      computed:Object.assign(computed, {

      }),
      watch:Object.assign(watch,{
        modal:function(){
          if(this.modal=="imageViewer"){
            setTimeout(()=>{
              this.openPhotoSwipe()
            },1)
          }else if(this.modal==null){
            if(this.pswp){
              this.pswp.close();
            }
          }
        },
        viewmode:function(){
          ROOT.scrollCommon.gotoScroll($(".item-image-holder .image-item").eq(0)[0]);
          if(ROOT.isSp){
            setTimeout(function(){
              ROOT.ev.emit("ON_CHANGE_MODE",1);
            },10)
          }
        }
      }),
      methods:{
        openPhotoSwipe: function(){
          this.pswpElement = document.querySelectorAll('.pswp')[0];
          var items=[]
          _.each(this.itemImages,(item,i)=>{
              items.push({
                src:item.src,
                w:1680,
                h:2240
              })
              console.log(item)
            })
          var options = {
            index:this.imageViewer,
            history: false,
            focus: false,
            spacing:0,
            maxSpreadZoom:2,
            pinchToClose:false,
            closeOnScroll:false,
            closeOnVerticalDrag:false,
            mouseUsed:true,
            preload:[1,1],
            showAnimationDuration: 0,
            hideAnimationDuration: 0,

            barsSize:{top:0, bottom:60},
            //arrowEl:false,
            preloaderEl:false,
            closeEl:false,
            captionEl: false,
            fullscreenEl: false,
            zoomEl: false,
            shareEl: false,
          };
          this.pswp = new PhotoSwipe( this.pswpElement, PhotoSwipeUI_Default, items, options);
          this.pswp.listen('close',()=>{
            this.modal=null;
          });
          this.pswp.init();
          this.pswp.zoomTo(Math.max(ROOT.s.W/1680,1), {x:ROOT.s.W*0.5,y:0});


        }
      }
    })
  }
}
