<template>
  <div class="list-layout-styles-slider wide-slider swiper-container padding-pc-left-lg padding-sp-left-ll">
    <div class="slide-nav-holder pcOnly">
      <div class="slide-nav-prev-holder"><div class="swiper-button-prev btn uk-flex uk-flex-center uk-flex-middle uk-border-circle uk-overflow-hidden bg-color-white margin0 box-shadow-def"><i class="icon icon-arrow icon-arrow-left" uk-icon="ka-arrow-left"></i></div></div>
      <div class="slide-nav-next-holder"><div class="swiper-button-next btn uk-flex uk-flex-center uk-flex-middle uk-border-circle uk-overflow-hidden bg-color-white margin0 box-shadow-def"><i class="icon icon-arrow icon-arrow-right" uk-icon="ka-arrow-right"></i></div></div>
    </div>
    <ul class="swiper-wrapper margin0">
      <li class="list-item item swiper-slide" v-for="(listItem,index) in $root.itemdata">
        <div class="list-alternate uk-flex uk-flex-between uk-flex-wrap uk-flex-row">
          <div class="list-alternate-item" v-for="(item,index) in listItem" v-html="item">

          </div>
        </div>
      </li>
    </ul>
    <div class="list-layout-styles-slider-bottom-trigger"> </div>
  </div>
</template>

<script>
export default {
  created () {
    var self = this
  },
  mounted () {
    var self = this;
    $(function(){
      ROOT.scrollCommon.styleFixNav();
    });
  },
  methods:{

  }
}
</script>

<style lang="scss">

</style>
