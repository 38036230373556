"use strict";
window.ROOT=window.ROOT||{};
ROOT.isDebug=location.hash=="#debug";
if(location.href.indexOf("localhost")!==-1||location.href.indexOf("192.168")!==-1)ROOT.isDebug=true;
if(!window.console) {window.console = { log: function(msg){} };}

window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.loadMode = 3;
//window.lazySizesConfig.preloadAfterLoad=true;
window.lazySizesConfig.customMedia = {
  '--small': '(max-width: 959px)',
  '--medium': '(min-width: 960px)',
  '--large': '(min-width: 1440px)',
};

import Resize from './module/_resize.webpack.js';
import ScrollCommon from './module/_scroll.webpack.js';
import Slideshow from './module/_slideshow.webpack.js';
import Swipers from './module/_swiper.webpack.js';
import Modal from './module/_modal.webpack.js';
import Utils from './module/_utils.webpack.js';
import ReadMore from './module/_readMore.webpack.js';
import Html from './module/_htmlElement.webpack.js';
import CommonHeader from './module/_header.webpack.js';
import Search from './module/_search.webpack.js';
import VueItemDetail from './module/_vueItemDetail.webpack.js';
import VueStylesDetail from './module/_vueStylesDetail.webpack.js';
import VueCartSeisan from './module/_vueCartSeisan.webpack.js';
import VueCartConfirm from './module/_vueCartConfirm.webpack.js';
import VueMemberInput from './module/_vueMemberInput.webpack.js';
import VueWishList from './module/_vueWishList.webpack.js';
import VueItemInCategory from './module/_vueItemInCategory.webpack.js';
import VueTop from './module/_vueTop.webpack.js';
import VueMypage from './module/_vueMypage.webpack.js';
import WpContent from './module/_wpContent.webpack.js';
import InputApply from './module/_input_apply.webpack.js';
import VimeoPlayerCtrl from './module/_vimeoPlayerCtrl.webpack.js';
import CategoryRefine from './module/_categoryRefine.webpack.js';




// init
;(function(){


  /*
  // デバック用の iframe 削除
  if(ROOT.isDebug && $("iframe").length ){
    $("iframe")[0].contentDocument.onclick=function(){
      $("iframe").remove()
    }
  }
  */

  // siteRoot取得
  //if(window.location.hostname=="demo-karrimor.ebisumart.com"){
  if(window.location.hostname.indexOf("ebisumart.com")!==-1){
    ROOT.siteRoot="/KARRIMOR/";
  }else{
    ROOT.siteRoot="/";
  }

  // assetsPath の取得
  var $img = $("footer.footer .brand-logo img");
  if($img.length)ROOT.assetsPath = $img.attr("src").split("assets/img/logo")[0];
  // 商品画像URL
  if(window.location.hostname.indexOf("ebisumart.com")!==-1||window.location.hostname.indexOf("localhost")!==-1){
    ROOT.productImagePath="//demo.ebisumart.com/client_info/KARRIMOR/itemimage/";
  }else{
    ROOT.productImagePath="//dbcn1bdvswqbx.cloudfront.net/client_info/KARRIMOR/itemimage/";
  }



  $("picture.assetsPath").each(function(i,el){
    var $el=$(el)
    $el.find("source").attr("data-srcset",ROOT.assetsPath+$el.find("source").attr("data-srcset"))
    $el.find("img").attr("data-src",ROOT.assetsPath+$el.find("img").attr("data-src"))
    var src=$el.find("img").attr("src");
    if(src&&src.indexOf(ROOT.assetsPath)!==0){
      $el.find("img").attr("src",ROOT.assetsPath+src);
    }
  })


  // 静的な lazyload のassetsPath 対応
  $(".wrap img.lazyload").not("picture img.lazyload").each(function(){
    var $this=$(this);
    var dataSrc=$this.attr("data-src");
    if(dataSrc&&dataSrc.indexOf("assets/img")!==-1){
      $this.attr("data-src",ROOT.assetsPath+dataSrc)
    }
    else if (dataSrc&&dataSrc.indexOf("images/")!==-1){
      $this.attr("data-src",ROOT.assetsPath+dataSrc)
    }
  })

  // 静的な lazyload完了後 のassetsPath 対応
  $(".wrap img.lazyloaded").not("picture img.lazyload").each(function(){
    if(this.src&&(this.src.indexOf("assets/img")==0||this.src.indexOf("./assets/img")==0||this.src.indexOf("../assets/img")==0)){
      this.src=ROOT.assetsPath+this.src;
    }
  });

  //site全体 lazyロード
  $(".wrap .contents img").not(".mainVisual-slider img, .swap-img, .swap-pic, picture img").each(function(i,el){
    var $el=$(el)
    if (!$el.attr("data-src")) {
      if (!$el.hasClass("notLazyload")) {
        $el.addClass("lazyload img-lazy-mode");
      }
    }
  });


  // メニュ下に潜るFlg
  if ($(".js-wrap-pt-delete").length) {
    $("body").addClass("wrap-top-0");
  }




  ROOT.touchable = Modernizr.touch;
  ROOT.click = ROOT.touchable?"tap":"click";
  ROOT.pageID = $(".wrap").attr("data-pageID");

  $("[data-v]").each(function(){
    var $el=$(this);
    var keyValue = $el.attr("data-v").split(",");
    $el.attr(keyValue[0],keyValue[1]).removeAttr("data-v");
    if(keyValue.length>2)$el.attr(keyValue[2],keyValue[3]);
    if(keyValue.length>4)$el.attr(keyValue[4],keyValue[5]);
  })
  $("[data-placeholder]").each(function(){
    var str=$(this).attr("data-placeholder")
    $(this).find("input,textarea").attr("placeholder",str)
  });
  $("[data-input-class]").each(function(){
    var str=$(this).attr("data-input-class")
    $(this).find("input").addClass(str)
    $(this).removeAttr("data-input-class");
  });
  $("[data-input-attr]").each(function(){
    var keyValue=$(this).attr("data-input-attr").split(",");
    $(this).find("input").attr(keyValue[0],keyValue[1]);
    $(this).removeAttr("data-input-attr");
  });

  var zeiritsu = 1.1;
  ROOT.teika_zeikomi = function(number){
    return Math.round(number * zeiritsu);
  }
  // 正規表現でセパレート
  ROOT.price_separate = function(num){
    return String(num).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }



  ROOT.ev = new EventEmitter();
  ROOT.resize = new Resize();
  ROOT.utils = new Utils();

  ROOT.vueItemDetail = new VueItemDetail();
  ROOT.vueStylesDetail = new VueStylesDetail();
  ROOT.vueCartSeisan = new VueCartSeisan();
  ROOT.vueCartConfirm = new VueCartConfirm();
  ROOT.vueMemberInput = new VueMemberInput();
  ROOT.vueWishList = new VueWishList();
  ROOT.vueItemInCategory = new VueItemInCategory();
  ROOT.vueMypage = new VueMypage();
  ROOT.vueTop = new VueTop();


  ROOT.html = new Html();
  ROOT.search = new Search();
  ROOT.categoryRefine = new CategoryRefine();
  ROOT.modal = new Modal();
  ROOT.readMore = new ReadMore();
  ROOT.commonHeader = new CommonHeader();
  ROOT.vimeoPlayerCtrl = new VimeoPlayerCtrl();
  ROOT.inputApply = new InputApply();

  ROOT.slider = new Swipers();
  ROOT.slideshow = new Slideshow();


  //parallaxの画像セット
  if ($(".uk-parallax-element").length) {
    $(".uk-parallax-element").each(function(i,el){
      var imgSrc = $(el).find(".parallax-img").attr("src")
      var imgSpSrc = $(el).find(".parallax-img-sp").attr("src")
      var param = $(el).attr("data-bgy");
      if (imgSpSrc && ROOT.isSp) {
        $(el).css({"background-image":"url("+imgSpSrc+")"});
      }
      else{
        $(el).css({"background-image":"url("+imgSrc+")"});
      }

      UIkit.parallax(el, { bgy: param });
      ROOT.ev.on("ON_CHANGE_TARGET",function(n){
        if (imgSpSrc && ROOT.isSp) {
          $(el).css({"background-image":"url("+imgSpSrc+")"});
        }
        else{
          $(el).css({"background-image":"url("+imgSrc+")"});
        }
        UIkit.parallax(el, { bgy: param });
      });
    })
  }


  //
  $(document).on("click",".disabled-click",function(){
    return false;
  });

  /* gtagイベント
  *********************/
  ROOT.gtagSend = function(action,label){
    var sendEl = null;
    sendEl = {'event_category': 'link','event_label': label}
    gtag('event',action,sendEl);
  }

  /* loadingイベント
  *******************/
  ROOT.loadFunc = function(pageID,oldPageID){
    setTimeout(function(){
      ROOT.ev.emit('CONTENT_SHOWN', 1);
    },100)
  }
  $("body").imagesLoaded(function(){
    ROOT.loadFunc()
    lazySizes.init()
  })
  ROOT.ev.on("CONTENT_SHOWN",function(n){
    $("body,.wrap,.header").removeClass("standBy");
    ROOT.setSwapImg();
  });

  ROOT.scrollCommon = new ScrollCommon();


  if ($(".wp-vue-holder").length) {
    ROOT.wpContent = new WpContent();
  }




  //URLコピー
  $(".btn-url-copy").attr("onClick",`window.execCopy('${location.href}',this)`);


})();

/**
window.onYouTubeIframeAPIReady = function(){
  ROOT.isYoutubeReady=true;
  $(ROOT).trigger("YOUTUBE-READY");
}
setTimeout(function(){
var tag = document.createElement('script');
tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
},100)
**/
