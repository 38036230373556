import Search from '../../components/Search.vue';

export default class {
  constructor(){
    document.addEventListener("DOMContentLoaded", (e)=> {
      if($(".searchModule").length){
        this.init();
      }
    })
  }
  init(){

    ROOT.vue=new Vue({
      delimiters: ['[[', ']]'],
      el: '.searchModule',
      components:{
        Search
      },
      data:{
        aa:""
      },
      watch:{

      },
      mounted:function(){

      },
      methods: {
        close:function(){
          console.log(this,"close")
        }
      }
    });
  }
}
