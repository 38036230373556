export default class {
  constructor(){
    var self=this;
    self.init();
  }
  init(){
    var self=this;
    self.$wrap=$(".wrap");
    self.current_scrollY=0;
    self.$scrollTarget=$( document );
    $(window).on("resize",function(){
      if (self.isModal) {self.setLayout();}
    });

    // モーダル（ビデオモーダル含む）
    $(".btn-modal").on("click",function(){
      var $this=$(this);
      var target=$this.attr("data-modal-target");
      var $modal=$(target);
      var videoID=$this.attr('data-modal-videoID');
      var videoIDSp=$this.attr('data-modal-videoID-sp');
      if(videoID){$modal.find(".vmPlayer").attr("data-videoID",videoID)}
      if(videoIDSp){$modal.find(".vmPlayer").attr("data-videoID-sp",videoIDSp)}
      $modal.addClass("modalShow").removeClass("modalHide");
      $modal.trigger("MODAL-OPEN")
    });
    $(".btn-modal-close , .modal .overlay").on("click",function(){
      var $this=$(this);
      var $modal=$this.parents(".modal")
      $modal.removeClass("modalShow").addClass("modalHide");
      $modal.trigger("MODAL-CLOSE")
      return false;
    });

  }
  setScrollPos(){
    var self=this;
    self.current_scrollY = self.$scrollTarget.scrollTop();
  }
  open(){
    var self=this;
    ROOT.ev.emit("ON_MODAL","OPEN");
    self.isModal = true;
    if(!self.current_scrollY)self.current_scrollY = self.$scrollTarget.scrollTop();
    self.$wrap.css({
      position: "fixed",
      left: 0,
      top: -1 * self.current_scrollY
    });

    setTimeout(function(){
      self.$scrollTarget.scrollTop(0);
    },100)
    $("body").removeClass("modalClose modalHide").addClass("modalOpen");
    $(function () {
      $("body").removeClass("modalClose modalHide").addClass("modalOpen");
    });

  }
  close(){
    var self=this;
    setTimeout(function(){
      self.$wrap.removeAttr("style");
      if (self.current_scrollY) {
        $( document ).scrollTop(self.current_scrollY);
      }
      ROOT.ev.emit("ON_MODAL","CLOSE");
      self.current_scrollY=null;
      $("body").removeClass("modalOpen modalHide").addClass("modalClose");
      self.isModal = false;
    },600)
    $(".modal").removeClass("centerLayout");
  }
  modalHide(){
    $("body").removeClass("modalClose").addClass("modalHide");
  }
  setLayout(){
    $(".modal").imagesLoaded(function(){
      var windowH = $(window).height()
      var contentH = $(".modal .modal-inner").outerHeight();
      if (contentH<windowH) {
        $(".modal").addClass("centerLayout")
      }
      else{
        $(".modal").removeClass("centerLayout")
      }
    });
  }
}

