export default class {
  constructor(){
    // 各レーベルでTOPでカテゴリーがあれば
    document.addEventListener("DOMContentLoaded", (e)=> {
      this.$vueEl=$('[data-vue-items-in-category]');
      if( this.$vueEl.length && location.hash!="#debug")this.init();
    })
  }
  init(){

    var targetCategorys = this.$vueEl.attr("data-vue-items-in-category");
    var max=this.$vueEl.attr("data-item-max")||20;
    if(targetCategorys){
      targetCategorys = targetCategorys.split(",");

    console.log($('[data-vue-items-in-category]').length)
      this.vue = new Vue({
        delimiters: ['[[',']]'],
        el: this.$vueEl[0],
        data: {
          max:max,
          targetCategorys:targetCategorys
        },
        created(){
        },
        mounted(){
        },
        computed:{
          targetItems:function(){
            var items = ROOT.JSON_DATA.items.filter((item)=>{
              var cats=[];
              for(var i=1;i<=30;i++){
                if(item["CATEGORY_CD"+i])cats.push(item["CATEGORY_CD"+i])
              }
              // if(cats.length)console.log(cats)
              // カテゴリー and 検索
              return _.every(targetCategorys,(cat)=>{
                return _.contains(cats,cat);
              });
            })
            items=_.map(items,function(item){
              item.ITEM_IMAGE1=ROOT.productImagePath+item.ITEM_IMAGE1;
              item.ITEM_IMAGE2=ROOT.productImagePath+item.ITEM_IMAGE2;
              item.ITEM_IMAGE3=ROOT.productImagePath+item.ITEM_IMAGE3;
              item.ITEM_IMAGE4=ROOT.productImagePath+item.ITEM_IMAGE4;
              item.href=ROOT.siteRoot+"item/"+item.ITEM_CD+".html";
              return item;
            })
            console.log(_.first(items,this.max))
            return _.first(items,this.max)
          }
        },
        watch:{
        },
        methods:{
        }

      })

    }
  }
}
